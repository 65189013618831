import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import mime from "mime-types";
AWS.config.update({
    //accessKeyId: 'AKIATPG2HYMJLAIZISWJ',
    //secretAccessKey: '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'
})

const s3 = new AWS.S3({
    params: { Bucket: "myauditapp"},
    region: "eu-west-2",
})
export const getSignedUrl = async(file)=>{
    try {
        let data = await s3.createPresignedPost({
            Fields: {
                key: `images/${new Date().getTime()}-${file.name}`,
            },
            Conditions: [
                ["content-length-range", 0, 1000000],
            ],
            Expires: 3000,
        })
        return data
    }catch (e) {
        return e
    }
    //      (err, signed) => {
    //     if(err)
    //         return console.log("err",err)
    //     console.log('signed',signed)
    //     return signed
    // });
}

export const arrangeFormData = (fields,file)=>{
    const data = {
        bucket: "myauditapp",
        ...fields,
        'Content-Type': file.type,
        file: file,
    };

    const formData  = new FormData();
    for (const name in data) {
        formData.append(name, data[name]);
    }
    return formData
}
export const sendDataIntoS3 = async (url,formData)=>{
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData.toString(),
        });
        console.log('response',response)
        return response
    }catch (e) {
        console.log('error',e)
    }

}
export const uploadMediaOns3= async (file,options={})=>{
    try {
       
	let org = localStorage.getItem("org")
        let orgObj = JSON.parse(org)
        let business_name = orgObj.business_name;

	let type = getFileType(file)
        let key = `${type}/${business_name}/${new Date().getTime()}-${file.name}`
        let contentType = mime.lookup(file.name) || file.type || "application/octet-stream";
        
        console.log("Uploading the image to s3")
	
        var params = {
            ACL: 'public-read',
            Bucket: 'myauditapp',
            Key: key,
            ContentType: getMimeType(file.name),
            Body: file
        };
        let result = await new Promise(((resolve, reject) => {
            s3.upload(params, options, function(err, data) {
                if(err){
                    console.log('err',err)
                    return reject(err)
                }else{
                    data.extension = type
                    data.key = key
                    return resolve(data);
                }
            });
        }))
        return result || null
    }catch (e) {
        return e
    }

}
export const deleteMediaFromS3Bucket = async (key)=>{
    var params = {
        Bucket: "myauditapp",
        Key: key
    };
    let result = await new Promise(((resolve, reject) => {
    s3.deleteObject(params, function(err, data) {
        if (err) {
            console.log(err, err.stack);
            reject(false)
        } // an error occurred
        else{
            console.log(data);
            resolve(true)
        }
    });
    }))
    return  result
}
const getFileType = (file)=>{
    if(file?.name?.includes(".docx") || file?.name?.includes(".csv"))
        return "files"
    else if(file.type.includes("image"))
        return "images"
    else if(file.type.includes("video"))
        return "videos"
    else
        return "files"
}

function getMimeType(filename) {
    const ext = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'txt': 'text/plain',
        'csv': 'text/csv',
        'mp4': 'video/mp4',
        'mp3': 'audio/mpeg'
        // Add more as needed
    };

    return mimeTypes[ext] || 'application/octet-stream';
}
